import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38aa58ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "btnbox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_form = _resolveComponent("van-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_nav_bar, {
      "left-arrow": "",
      title: _ctx.$route.meta.title,
      placeholder: "",
      onClickLeft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, null, 8, ["title"]),
    _createVNode(_component_van_form, {
      class: "updatePassword",
      ref: "updatePassword",
      "validate-trigger": "onSubmit"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_field, {
          modelValue: _ctx.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event)),
          name: "password",
          type: _ctx.password_eye ? 'text' : 'password',
          label: "新密码",
          placeholder: "请输入新密码",
          rules: [
          { required: true, message: '请输入新密码' },
          { pattern: _ctx.pattern, message: '密码必须八位以上，大小写字母+特殊字符' },
        ],
          "right-icon": _ctx.password_eye ? 'eye-o' : 'closed-eye',
          onClickRightIcon: _ctx.password_fn
        }, null, 8, ["modelValue", "type", "rules", "right-icon", "onClickRightIcon"]),
        _createVNode(_component_van_field, {
          modelValue: _ctx.repeatpassword,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.repeatpassword) = $event)),
          name: "repeatpassword",
          type: _ctx.password_eye_reset ? 'text' : 'password',
          label: "确认密码",
          placeholder: "确认密码",
          rules: _ctx.passwordRules,
          "right-icon": _ctx.password_eye_reset ? 'eye-o' : 'closed-eye',
          onClickRightIcon: _ctx.password_reset_fn
        }, null, 8, ["modelValue", "type", "rules", "right-icon", "onClickRightIcon"]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "submit-btn",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.fSubmitChange && _ctx.fSubmitChange(...args)))
          }, "提交")
        ])
      ]),
      _: 1
    }, 512)
  ]))
}