
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/user";

@Options({
  name: "Passworld",
})
export default class extends Vue {
  password = "";
  repeatpassword = "";
  password_eye = false;
  password_eye_reset = false;
  pattern = /(?=.*[A-Z])(?=.*[a-z])(?=.*[~!@#$%^&*_.]).{8,}/;
  passwordRules = [
    { required: true, message: "请输入确认密码" },
    {
      validator: (val: string) => {
        return val === this.password;
      },
      message: "两次密码输入不一致",
    },
  ];
  password_fn() {
    this.password_eye = !this.password_eye;
  }
  password_reset_fn() {
    this.password_eye_reset = !this.password_eye_reset;
  }

  fSubmitChange() {
    (this as any).$refs.updatePassword
      .validate()
      .then(async () => {
        const res: any = await api.updatePassword({
          password: this.password,
        });
        if (res.code !== 0) {
          return this.$toast.fail(res.msg);
        }
        this.$toast.success({
          message: "更新成功",
        });

        this.$router.go(-1);
      })
      .catch((err: any) => err);
  }
}
